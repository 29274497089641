.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  background: var(--bg-shop-item);
  border: 0.33px solid rgba(0, 0, 0, 0.08);
  border-radius: 8px;
}

.moneyCaption {
  padding-top: 16px;
  padding-right: 16px;
  padding-left: 16px;
  margin-bottom: 3px;
  color: var(--color-shop-item);
  opacity: 0.72;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.2px;
}

.moneyValue {
  color: var(--color-shop-item);
  font-weight: 500;
  font-size: 22px;
  line-height: 28px;
  padding-left: 22px;
}

.coinIcon {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 2px;
}

.between {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 16px;
  padding-right: 16px;
  padding-left: 16px;
}

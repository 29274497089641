.icon {
  text-align: center;
  position: absolute;
  z-index: 1;
  left: calc(50% - 38px);
}

.icon img {
  border-radius: 90px;
  width: 88px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.12), 0px 16px 16px rgba(0, 0, 0, 0.16);
}

.container {
  border-radius: 8px;
  padding: 64px 6px 6px 6px;
  margin: 40px auto 0 auto;
}

.header {
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  color: #ffffff;
  text-align: center;
  margin-bottom: 14px;
}

.item {
  display: flex;
  align-items: center;
  transition: background 0.5s ease-out;
  padding: 2px 0;
  padding-left: 4px;

  &:hover {
    background: var(--content_tint_background);
    cursor: pointer;
  }

  &.disabled {
    opacity: 0.5;
  }
}

.title {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.1px;
  margin-top: 0px;
  margin-bottom: 0px;
}

.icon {
  background: #F6F8FE;
  border-radius: 4px;
  padding: 4px;
  margin-right: 12px;
}
.header {
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  margin-top: 0px;
}

.container {
  padding: 0 5px;
}

.arrow {
  display: none;
  width: 28px;
  height: 28px;
  top: 35px;
  z-index: 1;
  position: absolute;
  transition: opacity 0.5s ease-in-out;

  &:hover {
    cursor: pointer;
    opacity: 0.5;
  }

  &.disabled {
    display: none;
  }

  &::before {
    content: "";
  }
}

@media (min-width: 500px) {
  .container {
    padding: 0 30px;
  }

  .arrow {
    display: block;
  }
}
// Переменные для светлой темы
body[scheme="bright_light"] {
  --color-custom-link: #3f8ae0;
  --background-education: radial-gradient(100% 177.21% at 0% 0%, #F7F9FF 0%, #EEF2FF 100%);
  --color-tool-item: #2c2e2d;
  --color-main-popup: #f6f8fe;
}

// Переменные для темной темы
body[scheme="space_gray"] {
  --color-custom-link: #4bb34b;
  --background-education: #000;
  --color-tool-item: #e1e3e6;
  --color-main-popup: #090909;
}
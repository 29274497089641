.section {
  display: flex;
  justify-content: space-between;

  &:first-child {
    margin-bottom: 10px;
  }
}

.header {
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  margin-top: 0px;
}

.container {
  padding: 0 0px;
}

@media (min-width: 500px) {
  .container {
    padding: 0 10px;
  }
}
.item {
  display: flex;
  align-items: center;
}

.itemCnt {
  padding: 0 6px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.1px;
  color: #2c2d2e;
}

.itemText {
  color: #818c99;
}

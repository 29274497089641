.itemCnt {
  margin-right: 8px;
  margin-bottom: 16px;
  position: relative;
}

.iconCnt {
  position: absolute;
  background: #3f8ae0;
  border-radius: 24px;
  padding: 4px;
  bottom: 8px;
  right: 4px;
}

.title {
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.2px;
  color: var(--text_primary);
  margin-top: 0px;
  margin-bottom: 0px;
}

.linkSection {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.link {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  color: var(--text_secondary);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  letter-spacing: 0.2px;
}

.add {
  background-color: #3f8ae0;
  position: absolute;
  left: 35px;
  top: 35px;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
}

.out {
  background-color: #e64646;
  position: absolute;
  left: 35px;
  top: 35px;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
}

.logo {
  display: flex;
  justify-content: center;
  margin-top: 38px;
  text-align: center;
  
  & img {
    z-index: 1;
  }
}

.placeholderCnt {
  background: var(--background-education);
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.btnCnt {
  position: absolute;
  bottom: 15px; 
  width: 100%;
}

.btn {
  width: 100%;
}
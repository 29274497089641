.item {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 2px;
  padding-bottom: 2px;
  min-width: 70px;
  max-width: 90px;
  cursor: pointer;
}

.item:hover {
  background: var(--content_tint_background);
}

.itemText {
  font-weight: normal;
  max-width: 90px;
  margin-top: 5px;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  letter-spacing: 0.2px;
}

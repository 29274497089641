.cnt {
  margin-top: 0px;
  width: 40%;
  align-self: center;
}

.subtitle {
  margin-top: 0px;
  margin-bottom: 2px;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.2px;
  color: var(--text_secondary);
}

.inputSection {
  width: 60%;
  min-width: 200px;
  position: relative;
}

.formLayoutGroup {
  display: flex;
  align-items: flex-start;
}

.container {
  background: var(--background-education);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  padding-top: inherit;
  padding-bottom: inherit;
  padding: 0px 12px;
}

.sectionTop {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.sectionCenter {
  margin-bottom: 15px;
}

.sectionBottom {
  width: 100%;
  margin-bottom: 15px;
}

.header {
  color: var(--text_primary);
}

.icon {
  margin-bottom: 12px;
}

.description {
  text-align: center;
  color: var(--text_placeholder);
}
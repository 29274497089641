.backgroundPC {
  background-color: #fff;
  position: relative;
  margin-left: 34px;
  margin-top: 34px;
  width: 13px;
  height: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
}

.onlinePC {
  background-color: #4bb34b;
  position: absolute;
  width: 9px;
  height: 9px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
}

.backgroundMobile {
  position: relative;
  background-color: #fff;
  margin-left: 34px;
  margin-top: 34px;
  width: 12px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
}

.container {
  margin: 6px 0;
  padding: 12px 0;
  border: 0.5px solid rgba(0, 0, 0, 0.08);
  border-radius: 8px;
}

.community {
  background-color: #4bb34b;
  position: absolute;
  left: 35px;
  top: 35px;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
}

.comment {
  background-color: #ffa000;
  position: absolute;
  left: 35px;
  top: 35px;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
}

.record {
  background-color: #3f8ae0;
  position: absolute;
  left: 35px;
  top: 35px;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
}

.itemCircle {
  display: flex;
  justify-content: center;
  align-items: center;
  background: radial-gradient(100% 177.21% at 0% 0%, #f7f9ff 0%, #eef2ff 100%);
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.03), 0px 2px 2px rgba(0, 0, 0, 0.06);
  border-radius: 50px;
  width: 88px;
  height: 88px;
  position: relative;
  margin-top: 8px;
  margin-bottom: 8px;
}

.itemContainer {
  text-align: center;
  color: #fff;
}

.itemTopText {
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.2px;
  color: #e9e9e9;
}

.itemBottomText {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.1px;
  color: #ffffff;
}

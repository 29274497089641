.title {
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.2px;
  text-transform: uppercase;
  color: var(--text_primary);
  margin-bottom: 8px;
}

.subtitle {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  margin-top: 0px;

  letter-spacing: 0.2px;

  color: var(--text_secondary);
  margin-bottom: 16px;
}

.container {
  margin-top: 14px;
  background: var(--bg-affiliate-program);
  border: 0.33px solid rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  padding: 16px;
  padding-top: 0px;
  padding-bottom: 6px;
}

.button {
  padding-left: 0px;
  padding-right: 0px;
}
.itemCnt {
  width: 79px;
  height: 130px;
  margin: 3px 3px;
  position: relative;
}

.itemTime {
  position: absolute;
  right: 4px;
  bottom: 4px;

  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;

  text-align: center;
  letter-spacing: 0.2px;
  padding: 2px 4px;

  color: var(--text_secondary);

  background: #ffffff;
  border-radius: 9px;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.topText {
  padding-bottom: 8px;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.2px;
  color: #e9e9e9;
}

.bottomText {
  font-weight: 500;
  font-size: 40px;
  color: #aa65f0;
  background: linear-gradient(to top, #c48aff 0%, #aa65f0 100%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.item {
  border-radius: 8px;
  display: flex;
  width: 100%;
  background: var(--color-main-popup);
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 20px 0;
  transition: opacity 0.5s ease-out;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}

.icon {
  margin-bottom: 6px;
}

.title {
  margin-top: 0px;
  margin-bottom: 0px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.2px;
}
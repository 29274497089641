.topContainer {
  display: flex;
  justify-content: space-between;
  text-align: center;
  margin-bottom: 14px;
}

.clockWrapper {
  max-width: 78px;
}

.clockContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 72px;
  height: 72px;
  border-radius: 50%;
  position: relative;
  background: #f6f8fe;
  text-align: center;
}

.clockTitle {
  font-size: 12px;
  margin-top: 8px;
  font-weight: normal;
  color: var(--text_secondary);
}

.btn {
  padding: 12px 12px;
  min-width: 100%;
  border: 1px solid var(--field_border);
  font-size: 16px;
  outline: none;
  border-radius: 8px;
  background: var(--field_background);
  text-align: left;
}

.text {
  color: #767676;
}

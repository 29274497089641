.bg {
  background-image: url("../../../../assets/img/profile/horoscopeBackground.svg");
  background-position: top;
  background-origin: border-box;
  background-size: cover;
  position: absolute;
}

.container {
  display: flex;
  justify-content: space-between;
}

.about {
  color: #fff;
  margin-top: 50px;
  margin-bottom: 80px;
}

.title {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.38px;
  color: #ffffff;
  margin-top: 0px;
  margin-bottom: 11px;
}

.desc {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: #e1e3e6;
  margin-top: 0px;
  margin-bottom: 20px;
}

.txt {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.2px;
}

.title {
  font-weight: 500;
  font-size: 17px;
  line-height: 20px;
}

// Миксин для загрузки шрифтов
@mixin font($font-family, $file-path, $weight: normal, $style: normal) {
  @font-face {
    font-family: $font-family;
    font-weight: $weight;
    font-style: $style;
    src: url("#{$file-path}.eot");
    src: url("#{$file-path}.eot?#iefix") format("embedded-opentype"),
      url("#{$file-path}.woff") format("woff"), url("#{$file-path}.ttf") format("truetype");
  }
}

#root {
  --font-common: "Roboto", sans-serif !important;
  font-synthesis: none;
}

.PanelHeader {
  --header_background: linear-gradient(90deg, #0841d0 0%, #0b64f4 100%);

  &__content {
    --font-tt: "Roboto", sans-serif;
    --header_text: #fff;

    font-size: 23px !important;
    font-style: normal !important;
    font-weight: bold !important;
  }
}

.Link {
  color: var(--color-custom-link) !important;
}

.Tabbar--ios {
  top: calc(100vh - 92px) !important;
}

.Epic--ios {
  position: relative !important;
  overflow: hidden !important;
}

.Root--ios {
  overflow: auto !important;
}

// Подключение шрифтов
@include font("RobotoRegular", "assets/fonts/Roboto/Roboto-Regular", "500");
@include font("RobotoMedium", "assets/fonts/Roboto/Roboto-Medium", "600");
// Подключение slick
@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";

// Fix IOS slick-slider
.slick-slider { 
  width: 1px; 
  min-width: 100%; 
  *width: 100%; 
}

.formLayoutGroup {
  display: flex;
  align-items: flex-start;
}

.paymentTitle {
  margin-top: 0px;
  margin-bottom: 2px;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.2px;
  color: var(--text_secondary);
}

.paymentValue {
  margin: 0px;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.38px;
}

.paymentSection {
  margin-top: 0px;
  width: 50%;
  margin-right: 100px;
  white-space: nowrap;
}

.inputSection {
  width: 50%;
  min-width: 200px;
  position: relative;
}

.inputImg {
  position: absolute;
  z-index: 5;
  right: 22px;
  top: 11px;
}

.choose-crush-list .Cell--android .Cell__checkbox:checked ~ .Cell__aside .Cell__checkbox-marker,
.choose-crush-list
  .Cell--android
  .Cell__checkbox:checked
  ~ .Cell__before
  .Cell__checkbox-marker
  .choose-crush-list
  .Cell--ios
  .Cell__checkbox:checked
  ~ .Cell__aside
  .Cell__checkbox-marker,
.choose-crush-list .Cell--ios .Cell__checkbox:checked ~ .Cell__before .Cell__checkbox-marker {
  width: 24px !important;
  height: 22px !important;
  color: inherit !important;
  background-color: inherit !important;
  background: url("../../../../../../assets/img/moycrush/heartFill.svg") no-repeat !important;

  .Icon {
    display: none !important;
  }
}

.choose-crush-item {
  .Cell__main {
    padding: 6px 8px 6px 0 !important;
  }

  .Cell__before {
    .Avatar {
      padding: 6px 12px 6px 0;
    }
  }

  .Cell__checkbox-marker {
    border: none;
    width: 24px;
    height: 22px;
    background: url("../../../../../../assets/img/moycrush/heartBlank.svg") no-repeat;
    border-radius: 0px;

    .Icon {
      display: none;
    }
  }

  .Cell__children {
    margin-bottom: 3px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.1px;
    color: var(--text_primary);
  }

  .Cell__description {
    font-style: normal;
    font-weight: 500;
    letter-spacing: 0.2px;
    color: var(--text_secondary);

    &:not(:empty) {
      font-size: 13px;
      line-height: 16px;
    }
  }
}

.item {
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: background 0.5s ease-out;
  padding: 5px 0;
  min-width: 75px;

  &:hover {
    background: var(--content_tint_background);
    cursor: pointer;
  }

  &.disabled {
    opacity: 0.5;
  }
}

.icon {
  margin-bottom: 4px;
}

.text {
  text-align: center;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: var(--color-tool-item);
  word-break: break-word;
  margin-top: 0px;
  margin-bottom: 0px;
}
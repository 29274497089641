.enterActive {
  opacity: 0;
  transform: scale(0.9);
  pointer-events: none;
}

.enterDone {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
  pointer-events: all;
}
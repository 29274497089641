.postContainer {
  margin: 6px 0;
  padding: 12px 0;
  border: 0.5px solid rgba(0, 0, 0, 0.08);
  border-radius: 8px;
}

.attachmentContainer {
  font-size: 13px;
  font-weight: normal;
  display: flex;
  flex-wrap: wrap;
  margin-top: 16px;
}

.attachmentItem {
  display: flex;
  align-items: center;
  color: #3f8ae0;
}

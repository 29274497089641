.item {
  padding-bottom: 16px;
  margin-top: 12px;
  border-bottom: 1px solid;
  border-bottom-color: var(--color-separator);
}

.title {
  color: var(--text_primary);
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.38px;
  margin-top: 0px;
  margin-bottom: 11px;
}

.subtitle {
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: var(--text_secondary);
  margin-top: 0px;
  margin-bottom: 12px;
}

.itemCnt {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  background: radial-gradient(109.55% 109.55% at 0% 0%, #358dff 0%, #0336ff 100%);
  border: 0.33px solid rgba(0, 0, 0, 0.08);
  box-sizing: border-box;
  border-radius: 8px;
}

.itemLeft {
  display: flex;
  align-items: center;
}

.img {
  margin-right: 8px;
}

.money {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: var(--white);
}

.day {
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.2px;
  opacity: 0.72;
  color: var(--white);
}

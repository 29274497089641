.header {
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  margin-top: 0px;
}

.section {
  display: flex;
  margin-bottom: 8px;
}
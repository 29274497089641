.container {
  text-align: center;
  padding: 9px 16px;
}

.item {
  padding: 4px 6px;
  background: #aeb7c2;
  color: var(--color-separate);
  border-radius: 12px;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.2px;
}

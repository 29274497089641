.notCoinTitle {
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.38px;
  color: var(--text_primary);
  margin-bottom: 11px;
}

.needCoinTitle {
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.38px;
  color: var(--text_secondary);
}

.bottomTitle {
  color: var(--text_secondary);
  font-weight: normal;
  font-size: 11px;
  line-height: 14px;
  letter-spacing: 0.3px;
  text-decoration: underline;
}

body[scheme="bright_light"] {
  --color-separator: #d7d8d9;
  --bg-shop-item: radial-gradient(100% 177.21% at 0% 0%, #f7f9ff 0%, #eef2ff 100%);
  --color-shop-item: #000;
  --bg-card-pay-item-selected: #f2f3f5;
}

body[scheme="space_gray"] {
  --color-separator: #2c2d2e;
  --bg-shop-item: radial-gradient(100% 177.21% at 0% 0%, #3b3c3f 0%, #2a2b30 100%);
  --color-shop-item: #c4c8cc;
  --bg-card-pay-item-selected: #2c2d2e;
}

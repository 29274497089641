.outContainer {
  height: 80px;
  padding: 4px;
  margin-right: 2px;
  border: 1px solid;
  border-color: var(--bg-card-pay-item-selected);
  border-radius: 4px;
  position: relative;
}

.inContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.2px;
  margin-top: 4px;
  margin-bottom: 0px;
  text-align: center;
  color: var(--text_secondary);
}

.balance {
  font-family: "RobotoRegular", sans-serif;
  background: linear-gradient(90deg, #0841d0 0%, #0b64f4 100%);
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.balanceCoinCnt {
  display: flex;
  flex-direction: column;
}

.balanceCoinCnt:hover {
  cursor: pointer;
}

.balanceMoneyCaption {
  font-weight: 500;
  font-size: 11px;
  line-height: 14px;
  letter-spacing: 0.3px;
  opacity: 0.7;
}

.balanceMoneyText {
  font-size: 20px;
  font-weight: bold;
  line-height: 24px;
  letter-spacing: 0.38px;
  padding-left: 22px;
  position: relative;
}

.balanceMoneyImg {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 2px;
  left: 0px;
}

.lineCompareContainer {
  display: flex;
  justify-content: space-between;
  padding-top: 12px;
  padding-bottom: 12px;
  border-bottom: 1px solid #d7d8d9;
}

.lineCompareContainer:last-child {
  border-bottom: none;
}

.lineCompareLeft {
  display: flex;
  align-items: center;
  padding: 6px 8px;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.2px;
}

.lineCompareCenter {
  border: 1px solid #e1e3e6;
  border-radius: 24px;
  padding: 6px 8px;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.2px;
}

.lineCompareRight {
  display: flex;
  align-items: center;
  padding: 6px 8px;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.2px;
}
